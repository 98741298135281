import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { PageName } from '../../models/PageName';
import { useLazyGetProfileQuery } from '../../providers/reducers/userProfileApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { CommonConstants } from '../../CommonConstants';
import TagManager from 'react-gtm-module';

export const Callback = () => {
    const [query] = useSearchParams();
    const navigate = useNavigate();
    const { isAuthenticated, isLoading } = useAuth0();
    const [getProfile] = useLazyGetProfileQuery();

    useEffect(() => {
        if (!isLoading && isAuthenticated) (async () => await triggerGetProfile())();
    }, [query, isAuthenticated, isLoading]);

    const triggerGetProfile = async () => {
        const redirectUrl = query.get('url');
        const result = await getProfile({});

        if (result.isSuccess && result.data) {
            localStorage.setItem(CommonConstants.LOCALSTORAGE_USER_KEY, JSON.stringify(result.data));

            TagManager.dataLayer({
                dataLayer: {
                    event: 'signed_in',
                    userId: result.data.Id,
                    dlv_user_type: result.data.BusinessType,
                    dlv_user_role: result.data.Role,
                    dlv_abn_provided: !!result.data.Abn,
                },
            });
            if (redirectUrl?.includes(PageName.SignIn)) navigate(`/${PageName.MyAccount}`, { replace: true });
            else if (redirectUrl) navigate(redirectUrl, { replace: true });
        } else {
            navigate(`/${PageName.SignIn}/${PageName.CompleteYourProfile}`, { replace: true });
        }
    };

    return <LoadingOverlay active={true} spinner={<SyncLoader />} text="Fetching your profile. Please wait..." className="loader mt-8" />;
};
