import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react';
import { ComButton } from '@exporter-services/common-ui';
import { MenuItemData } from './MenuItemData';
import './AccountMenu.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { PageName } from '../../models/PageName';
import { CommonConstants } from '../../CommonConstants';
import { isExpansionWorkEnabled } from '../../config';

interface AccountMenuProps {
    data: {
        menu: Array<MenuItemData>;
    };
}

const AccountMenu = (props: AccountMenuProps) => {
    const { isAuthenticated, isLoading, logout, user } = useAuth0();
    const [showAccountMenu, setShowAccountMenu] = useState(false);
    const [accountMenuItems, setAccountMenuItems] = useState<any>(null);
    const displayName = useMemo(() => (user?.given_name ? `${user?.given_name} ${user?.family_name}` : '\u00A0'), [user]);
    const initials = useMemo(
        () => (user?.given_name ? `${user?.given_name?.charAt(0).toUpperCase()}${user?.family_name?.charAt(0).toUpperCase()}` : '\u00A0'),
        [user],
    );

    const flattenMenuItems = (items: Array<MenuItemData>) => {
        let result = [];
        items.forEach((item: MenuItemData) => {
            result.push(item);
            if (Array.isArray(item.menuItems)) result = result.concat(flattenMenuItems(item.menuItems));
        });

        return result;
    };

    const buildAccountMenu = (items: MenuItemData[]) => {
        return items.map((item: MenuItemData) => {
            return {
                id: item.id,
                title: item.title,
                url: item.url,
                menuIcon: item.menuIcon,
            };
        });
    };

    const hideAccountMenu = (event: BaseSyntheticEvent | UIEvent) => {
        if (!event.target?.parentNode?.closest?.('.account-wrapper')) setShowAccountMenu(false);
    };

    const handleKeyDown = (event: BaseSyntheticEvent | KeyboardEvent) => {
        const keyPressed = (event as KeyboardEvent).key;
        const openMenu = keyPressed === 'ArrowDown';
        const closeMenu = keyPressed === 'Escape';

        if (openMenu || closeMenu) {
            event.preventDefault();
            event.stopPropagation();
            setShowAccountMenu(openMenu);

            if (closeMenu) (document.querySelector('.my-account-button') as HTMLButtonElement)?.focus();
        }
    };

    const signout = (event: React.MouseEvent | KeyboardEvent) => {
        event.preventDefault();
        localStorage.removeItem(CommonConstants.LOCALSTORAGE_USER_KEY);
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    useEffect(() => {
        window.addEventListener('mousedown', hideAccountMenu);

        return () => window.removeEventListener('mousedown', hideAccountMenu);
    }, []);

    useEffect(() => {
        if (props.data?.menu) {
            const filteredAccountItems = flattenMenuItems(props.data.menu).filter((item) => item.showInAccountMenu);
            const accountMenuItems = buildAccountMenu(filteredAccountItems);

            if (accountMenuItems) setAccountMenuItems(accountMenuItems);
        }
    }, [props.data?.menu]);

    return (
        <div className="account-wrapper">
            {!isLoading &&
                (!isAuthenticated ? (
                    isExpansionWorkEnabled && (
                        <ComButton
                            onClick={() => {
                                window.location.href = `/${PageName.SignIn}`;
                            }}
                            variant="link"
                            className="person-icon"
                        >
                            Sign in&nbsp;
                        </ComButton>
                    )
                ) : (
                    <ComButton
                        variant="link"
                        className="my-account-button"
                        onClick={() => setShowAccountMenu((flag) => !flag)}
                        onKeyDown={handleKeyDown}
                    >
                        <div className="account-initials">{initials}</div>
                        <span className={`my-account arrow-down-icon${showAccountMenu ? ' open' : ''}`}>My account</span>
                    </ComButton>
                ))}
            {showAccountMenu && (
                <div
                    className="account-menu"
                    onBlur={() => {
                        setShowAccountMenu(false);
                    }}
                    onKeyDown={handleKeyDown}
                >
                    <div>
                        <div className="account-initials menu-item">{initials}</div>
                        <div className="account-name" title={displayName}>
                            {displayName}
                        </div>
                    </div>
                    <div className="menu-items-container">
                        {accountMenuItems?.map((item: any) => (
                            <a
                                className="menu-item"
                                key={item.id}
                                href={item.url}
                                onBlur={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                {item.menuIcon?.value?.[0] ? (
                                    <img className="icon" src={item.menuIcon.value[0].url} alt={item.menuIcon.value[0].description || ''} />
                                ) : (
                                    <div className="icon-placeholder"></div>
                                )}
                                <div className="menu-title">{item.title}</div>
                            </a>
                        ))}
                    </div>
                    <a className="menu-item" href="/" onClick={(e: React.MouseEvent) => signout(e)}>
                        <div className="icon signout-icon"></div>
                        <div className="menu-title">Sign out</div>
                    </a>
                </div>
            )}
        </div>
    );
};

export default AccountMenu;
