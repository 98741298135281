import React, { useRef, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { ComButton } from '@exporter-services/common-ui';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { AbnField } from './AbnField';
import FocusTrap from 'focus-trap-react';

interface AbnModalProps {
    onCloseClick?: () => void;
}
export const AbnModal = (props: AbnModalProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const [displayLoader, setDisplayLoader] = useState(false);
    const formContext = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
    const {
        handleSubmit,
        formState: { isSubmitting },
    } = formContext;

    const saveAbnProvided = async (data: FieldValues) => {
        if (displayLoader) return;
        // Todo: Save Abn before closing
        props?.onCloseClick();
    };

    return (
        <>
            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal={true} aria-hidden={true} tabIndex={-1} role="dialog">
                <div className="modal">
                    <LoadingOverlay active={displayLoader} spinner={<SyncLoader />} text="Fetching ABN details. Please wait..." className="loader">
                        <FocusTrap>
                            <FormProvider {...formContext}>
                                <div className="modal-close close-icon" onClick={props?.onCloseClick}></div>
                                <form
                                    className={`${displayLoader ? 'overflow-hidden' : ''}`}
                                    onSubmit={handleSubmit((data) => saveAbnProvided(data))}
                                    ref={formRef}
                                >
                                    <h2>Provide your ABN</h2>
                                    <p>You need to provide your Australian Business Number (ABN) to access webinars. We'll only ask for this once.</p>
                                    <AbnField required isFetchingAbnDetails={setDisplayLoader} />
                                    <div className="button-container">
                                        <ComButton type="submit" disabled={isSubmitting}>
                                            Save
                                        </ComButton>
                                    </div>
                                </form>
                            </FormProvider>
                        </FocusTrap>
                    </LoadingOverlay>
                </div>
            </div>
        </>
    );
};
