import React, { useEffect, useState } from 'react';
import './AuthenticationModal.scss';
import Authentication from './Authentication';
import { ContentItem } from '@exporter-services/common-ui';
import { client } from '../../config';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import FocusTrap from 'focus-trap-react';

interface AuthenticationModalProps {
    onCloseClick?: () => void;
}

const AuthenticationModal = (props: AuthenticationModalProps) => {
    const [content, setContent] = useState<ContentItem>();
    const [displayLoader, setDisplayLoader] = useState(false);

    useEffect(() => {
        setDisplayLoader(true);
        client
            .items()
            .equalsFilter('system.type', 'authentication_call_to_action')
            .equalsFilter('system.codename', 'modal_auth_call_to_action')
            .depthParameter(0)
            .toPromise()
            .then((response) => {
                setDisplayLoader(false);
                setContent(response.data.items[0]);
            });
    }, []);

    return (
        <>
            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal={true} aria-hidden={true} tabIndex={-1} role="dialog">
                <div className="modal authentication-modal">
                    <LoadingOverlay active={displayLoader} spinner={<SyncLoader />} text="Please wait..." className="loader">
                        {content && (
                            <FocusTrap>
                                <div>
                                    <div className="modal-close close-icon" onClick={props?.onCloseClick}></div>
                                    <h3>Sign in to book</h3>
                                    <Authentication data={{ item: content, isModal: true }} />
                                </div>
                            </FocusTrap>
                        )}
                    </LoadingOverlay>
                </div>
            </div>
        </>
    );
};

export default AuthenticationModal;
