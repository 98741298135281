import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { ContentItem } from '@exporter-services/common-ui';
import './EventResultsContainer.scss';
import { client } from '../../config';
import { EventTilePathwayItem } from '../eventTilePathway/EventTilePathwayItem';
import EventTilePathway from '../eventTilePathway/EventTilePathway';
import HeadingLevelResolver from '../headingLevelResolver/HeadingLevelResolver';
import { EventTag } from '../../models/EventTag';

interface EventResultsContainerProps {
    data: {
        item: ContentItem;
    };
}

const EventResultsContainer = (props: EventResultsContainerProps) => {
    const onDemandLabel = EventTag.OnDemand;
    const [eventPageContentItems, setEventPageContentItems] = useState<EventTilePathwayItem[]>(null);

    useEffect(() => {
        getEventPageContentItems();
    }, []);

    const sortEventsByStartDate = (next: ContentItem, prev: ContentItem) => {
        let nextEventDetails = next.elements.content['linkedItems'].find((i: ContentItem) => i.system.type === 'event_details');
        if (!nextEventDetails) {
            nextEventDetails = next.elements.right_hand_content['linkedItems'].find((i: ContentItem) => i.system.type === 'event_details');
        }

        let prevEventDetails = prev.elements.content['linkedItems'].find((i: ContentItem) => i.system.type === 'event_details');
        if (!prevEventDetails) {
            prevEventDetails = prev.elements.right_hand_content['linkedItems'].find((i: ContentItem) => i.system.type === 'event_details');
        }

        return dayjs(nextEventDetails?.elements?.start_date?.value).diff(prevEventDetails?.elements?.start_date?.value);
    };

    const getEventPageContentItems = () => {
        if (!(props.data.item?.elements?.event_type?.value?.length > 0)) {
            return;
        }

        let eventType = props.data.item?.elements?.event_type?.value[0].codename;

        //get contents by using event type as filter
        if (props)
            client
                .items()
                .equalsFilter('system.type', 'event_page')
                .containsFilter('elements.event_type', [eventType])
                .depthParameter(2)
                .toPromise()
                .then((response) => {
                    setEventPageContentItems([
                        ...response.data.items
                            .filter((item) => item.elements.label?.value?.toLowerCase() !== onDemandLabel)
                            .sort(sortEventsByStartDate),
                        ...response.data.items
                            .filter((item) => item.elements.label?.value?.toLowerCase() === onDemandLabel)
                            .sort(sortEventsByStartDate),
                    ]);
                });
    };

    return (
        <>
            {eventPageContentItems?.length > 0 && (
                <div className="event-results-container">
                    <HeadingLevelResolver
                        data={{
                            headingLevel: props.data.item.elements.headinglevelsnippet__level,
                            titleText: props.data.item.elements.title?.value,
                        }}
                    />
                    <Row>
                        <Col>
                            {eventPageContentItems?.length > 0 ? (
                                <h2 className="results-count-header">
                                    Showing <strong>{eventPageContentItems.length}</strong> {eventPageContentItems.length === 1 ? 'event' : 'events'}
                                </h2>
                            ) : (
                                <h2 className="results-count-header">No events to show</h2>
                            )}
                        </Col>
                    </Row>
                    <Row className="event-results-container-items full-width">
                        {eventPageContentItems.map((item: EventTilePathwayItem) => (
                            <Col lg={4} sm={6} className="list-block" key={item.system.id}>
                                <EventTilePathway data={{ content: item }} client={client} key={item.system.id} />
                            </Col>
                        ))}
                    </Row>
                </div>
            )}
        </>
    );
};

export default EventResultsContainer;
