import React, { useState } from 'react';
import './EventDetails.scss';
import { formatEventStartDateEndDate } from '../../utils/dateUtils';
import { ComButton, ContentItem } from '@exporter-services/common-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { AbnModal } from '../abn/AbnModal';
import AuthenticationModal from '../authentication/AuthenticationModal';

interface EventDetailsProps {
    data: {
        item: ContentItem;
    };
}

const EventDetails = (props: EventDetailsProps) => {
    const { isAuthenticated } = useAuth0();
    const [showAbnModal, setShowAbnModal] = useState(false);
    const [showAuthenticationModal, setShowAuthenticationModal] = useState(false);

    const handleAbnModalCloseClick = () => {
        setShowAbnModal(false);
    };

    const handleAuthenticationModalCloseClick = () => {
        setShowAuthenticationModal(false);
    };

    const handleEventBookingClick = () => {
        if (isAuthenticated) {
            setShowAbnModal(true);
        } else {
            setShowAuthenticationModal(true);
        }
    };

    const getEventDateDetails = () => {
        let result = formatEventStartDateEndDate(props.data.item.elements?.start_date?.value, props.data.item.elements?.end_date?.value);
        return result;
    };

    return (
        <div className="event-details pb-5">
            <div className="event-details-content-wrapper">
                <div className="event-details-content p-4 h-100">
                    <div className="center-icon">
                        <div className="icon-wrapper me-3 mb-3">
                            <span className="event-icon"></span>
                        </div>
                    </div>
                    {props.data.item.elements?.title?.value && <h2>{props.data.item.elements.title.value}</h2>}
                    <div className="item">
                        <div className="label">Date:</div>
                        <div dangerouslySetInnerHTML={{ __html: getEventDateDetails() }}></div>
                    </div>
                    <div className="item">
                        <div className="label">Time:</div>
                        <div>{props.data.item.elements?.time?.value}</div>
                    </div>
                    <div className="item">
                        <div className="label">Location:</div>
                        <div>{props.data.item.elements?.location?.value}</div>
                    </div>
                    <div className="item">
                        <div className="label">Cost:</div>
                        <div>{props.data.item.elements?.cost?.value}</div>
                    </div>
                </div>
                <div className="event-link p-4">
                    <ComButton onClick={handleEventBookingClick}>{isAuthenticated ? <>Book</> : <>Sign in to book</>}</ComButton>
                </div>
            </div>
            {showAbnModal && <AbnModal onCloseClick={handleAbnModalCloseClick} />}
            {showAuthenticationModal && <AuthenticationModal onCloseClick={handleAuthenticationModalCloseClick} />}
        </div>
    );
};

export default EventDetails;
